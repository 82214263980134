<template>
<div class="container-login">
  <div class="wrap-logo" v-if="haveUserInUrl">
    <div class="content-logo">
      <div>
        <div class="welcome">
          <div class="title">{{$t('login.welcome.title')}}</div>
          <div class="msg">{{$t('login.welcome.msg')}}</div>
          <div class="loading mt-3" v-if="haveUserInUrl">{{$t('login.welcome.loading')}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="wrap-logo d-none d-md-block" v-if="!haveUserInUrl">
    <div class="content-logo">
      <div>
        <img class="logo" :src="urlLogo"/>
        <div class="welcome">
          <div class="title">{{$t('login.welcome.title')}}</div>
          <div class="msg">{{$t('login.welcome.msg')}}</div>
        </div>
      </div>
      <img class="illustration d-none d-md-block" :src="urlIllustration"/>
    </div>
  </div>
  <div class="wrap-login" v-if="!haveUserInUrl">
    <div class="d-block d-md-none">
      <div class="welcome">
        <div class="title">{{$t('login.welcome.title')}}</div>
        <div class="msg">{{$t('login.welcome.msg')}}</div>
      </div>
    </div>
    <form class="login-form" @submit.prevent="login">
      <div class="title mb-5 d-none d-md-block">{{$t('login.title')}}</div>
      <div class="wrap-input">
        <input data-username v-model="username" :placeholder="$t('login.input.user')" required autocomplete="off" class="input" type="text">
        <span class="focus-input"></span>
        <span class="symbol-input">
          <i class="fa fa-user" aria-hidden="true"></i>
        </span>
      </div>

      <div class="wrap-input">
        <input data-password autocomplete="off" v-model="password" :placeholder="$t('login.input.password')" class="input" required type="password">
        <span class="focus-input"></span>
        <span class="symbol-input">
          <i class="fa fa-lock" aria-hidden="true"></i>
        </span>
      </div>
      <div class="container-login-form-btn">
        <button class="login-form-btn">
          {{$t('login.button')}}
        </button>
      </div>

    </form>
    <img class="illustration d-block d-md-none" :src="urlIllustration"/>
  </div>
</div>
</template>
<script>
import i18n from './i18n'
import { PUBLIC_PATH } from '@/enviroment/global'
import { APP_REDIRECT_ON_LOGIN, APP_PAGENAME_REDIRECT_ON_LOGIN, IS_EXTERNAL_MODE, LOGOUT_PATH } from '@/enviroment/app'
import { LOGIN_REDIRECTION_QUERY, LOGIN_REDIRECTION_NAME } from '@/views/login'
import { Store, USER, PLAKA } from '@/store'

export default {
  name: 'loginBase',
  data () {
    return {
      username: (new URLSearchParams(window.location.search)).get('username') || '',
      password: ''
    }
  },
  computed: {
    urlIllustration () {
      return `${PUBLIC_PATH}images/ilustracion.svg`
    },
    urlLogo () {
      return `${PUBLIC_PATH}images/logo.svg`
    },
    haveUserInUrl () {
      return !!this.queryParams.get('token') || !!this.queryParams.get('username')
    },
    token () {
      return this.queryParams.get('token')
    },
    queryParams () {
      // Al tratarse de endpoints abstractos no va el this.$route.query
      return new URLSearchParams(window.location.search)
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
      this.autoLogin()
    },
    async autoLogin () {
      if (this.queryParams.get('authPage')) {
        Store.commit({
          type: USER.MUTATIONS.AUTH_PAGE,
          url: this.queryParams.get('authPage')
        })
      } else {
        Store.commit({
          type: USER.MUTATIONS.AUTH_PAGE,
          url: undefined
        })
      }
      if (this.token) {
        try {
          await this.login()
          let params = new URLSearchParams(window.location.search)
          params.delete('authPage')
          params.delete('token')
          params.delete('username')
          history.replaceState(null, '', `?${params}${window.location.hash}`)
        } catch (e) {
          await this.$router.push({ name: LOGOUT_PATH })
        }
      }
    },
    login () {
      Store.commit(PLAKA.MUTATIONS.REMOVE_FILTERS, {})
      this.$servicesPLK.connection.getConnections(this.$servicesPLK.connection.TYPE.PLAKA).then(async (connections) => {
        await Promise.all(connections.map(conn => conn.loginInApp({ username: this.username, password: this.password, token: this.token })))
        if (!IS_EXTERNAL_MODE) {
          await this.$router.push({
            name: this.$route.query[LOGIN_REDIRECTION_NAME] || APP_PAGENAME_REDIRECT_ON_LOGIN,
            params: { id: this.$route.query[LOGIN_REDIRECTION_QUERY] || APP_REDIRECT_ON_LOGIN }
          })
        }
        // Necesitamos refrescar por un bug en la entrada desde login que impide el cambio
        location.reload()
      })
    }
  }
}
</script>

<style scoped lang="scss">.container-login {
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & {
    overflow: hidden;
    background: #F4DCC5;
    background: transparent linear-gradient(180deg, #FAEDE0 0%, #F4DCC5 100%) 0% 0% no-repeat padding-box;
  }

  .wrap-logo {
    width: 60%;
    height: 100vh;
    overflow: hidden;
    .content-logo {
      margin: 2%;
      height: 95%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .logo {
        width: 5%;
      }
      .welcome {
        margin: 3rem 0rem;
        .title {
          text-align: left;
          font-weight: bold;
          font-size: 3.5rem;
          line-height: 3.5rem;
          letter-spacing: 0px;
          color: #F24735;
        }
        .msg {
          margin-top: 1.5rem;
          line-height: 3.5rem;
          text-align: left;
          font-weight: bold;
          font-size: 3rem;
          letter-spacing: 0px;
        }
      }
    }
  }
  .illustration {
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }
  .wrap-login {
    width: 30%;
    margin: 0;
    padding: 5%;
    background: white;

    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    @media (max-width: breakpoint-max('md', $grid-breakpoints)) {
      & {
        justify-content: space-evenly;
        width: 100%;
        height: 100%;
        margin: 0;
        background: #F4DCC5;
        background: transparent linear-gradient(180deg, #FAEDE0 0%, #F4DCC5 100%) 0% 0% no-repeat padding-box;
      }

      .welcome {
        margin: 3rem 0rem;
        .title {
          text-align: left;
          font-weight: bold;
          font-size: 2.5rem;
          line-height: 3.5rem;
          letter-spacing: 0px;
          color: #F24735;
        }
        .msg {
          margin-top: 1.5rem;
          line-height: 3.5rem;
          text-align: left;
          font-weight: bold;
          font-size: 2rem;
          letter-spacing: 0px;
        }
      }
    }

    .title {
      width: 100%;
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
      letter-spacing: 0px;
      color: #232323;
    }
    .wrap-input {
      position: relative;
      width: 100%;
      z-index: 1;
      margin-bottom: 10px;

      .input {
        line-height: 1.5;
        color: #666666;
        display: block;
        width: 100%;
        background: #fcf8f4;
        height: 50px;
        border-radius: 25px;
        padding: 0 30px 0 68px;

        &:focus {
          -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
          animation: anim-shadow 0.5s ease-in-out forwards;
          border: 2px solid #F24735;
          background: #ffffff;

          + {
            .focus-input {
              + {
                .symbol-input {
                  color: $color-base;
                  padding-left: 28px;
                }
              }
            }
          }
        }
      }

      .symbol-input {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        position: absolute;
        border-radius: 25px;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-left: 35px;
        pointer-events: none;
        color: #988C8C;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
      }
    }
    .login-form-btn {
      line-height: 1.5;
      color: #fff;
      text-transform: uppercase;

      width: 100%;
      height: 50px;
      border-radius: 25px;
      background: $color-base;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 25px;
      margin-bottom: 0px;
      border: 0px;
      -webkit-transition: all 0.4s;
      -o-transition: all 0.4s;
      -moz-transition: all 0.4s;
      transition: all 0.4s;

      &:hover {
        background: #eeb868;
      }
    }

  }
}

</style>
